import React from "react";
import {Link} from "gatsby";

function Relevant() {
  return (
    <div className=" bg-green-default">
      <div className="section-container">
       <div>
          <h1 className="heading mt-9 mb-10">This is why Bioblinds are relevant</h1>
          <ul>
            <li className="info-items"><a href="https://www.nzz.ch/meinung/klimademokratie-statt-klimanotstand-die-staedte-sind-gefragt-ld.1513093" target="__blank" className="info-items">– Klimademokratie statt Klimanotstand – die Klimawende wird in den Städten entschieden</a></li>
            <li className="info-items"><a href="http://www.growinggreenguide.org/wp-content/uploads/2014/12/Thami_Croeser_-_628725_-_Final_Thesis.pdf" target="__blank" className="info-items">– The next green hectare will be vertical</a></li>
          </ul>
        </div>
        <div>
          <h1 className="heading mt-9 mb-10">What is interesting to consider?</h1>
          <ul>
            <li className="info-items"><a href="https://openweathermap.org/" target="__blank" className="info-items">– Weather all around the world</a></li>
            <li className="info-items"><a href="http://www.rainviewer.com/" target="__blank" className="info-items">– Rain all around the world</a></li>
            <li className="info-items"><a href="http://windy.com/" target="__blank" className="info-items">– Winds all round the world</a></li>
            <li className="info-items"><a href="https://www.coaps.fsu.edu/~bourassa/scat_html/forcing_tut/forcing_tutorial.php" target="__blank" className="info-items">– Wind and height correlations</a></li>
            <li className="info-items"><a href="https://www.climate-charts.com/world-index.html" target="__blank" className="info-items">– Climate Charts</a></li>
            <li className="info-items"><a href="https://thegreenskylineinitiative.com/files/kletterpflanzen.pdf" target="__blank" className="info-items">– Mit Seil und Haken an der Wand – Empfehlenswerte Kletterpflanzen für den Profi</a></li>
            <li className="info-items"><a href="https://thegreenskylineinitiative.com/files/klimafassaden_utopie.pdf" target="__blank" className="info-items">– Grüne Klimafassaden – Utopie und Wirklichkeit</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Relevant;
