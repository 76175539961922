import React from "react";
import Img1 from "./../../images/tools/1.png";
import Img2 from "./../../images/tools/2.png";
import Img3 from "./../../images/tools/3.png";
import Img4 from "./../../images/tools/4.png";
import Img5 from "./../../images/tools/5.png";

function Tools() {
  return (
    <div className=" bg-green-default">
      <div className="section-container">
        <div>
          <h1 className="heading md:mb-20 mb-10">
            Tools for ‘Bioblinds – The Green Skyline Initiative’
          </h1>
        </div>
        <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1  gap-5">
          {DataTools.map((DataTool) => {
            return (
              <div className="bg-white px-6 py-5">
                <div className="flex  flex-col items-center justify-between w-full">
                  <div className="w-full flex justify-center">
                    {" "}
                    <img src={DataTool.img} alt="" className=" w-36" />
                  </div>
                  <div className="pt-6">
                    <a href={DataTool.url} target="__blank" className="hover:text-green-default boxes-links">
                      {DataTool.title}
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Tools;

const DataTools = [
  {
    id: 1,
    title: "draw.io",
    description: "desc",
    img: Img1,
    url: "https://app.diagrams.net/",
  },
  {
    id: 2,
    title: "WordPress",
    description: "desc",
    img: Img2,
    url: "https://de.wordpress.org/",
  },
  {
    id: 3,
    title: "Tinkercad",
    description: "desc",
    img: Img3,
    url: "https://www.tinkercad.com/",
  },
  {
    id: 4,
    title: "Google Forms",
    description: "desc",
    img: Img4,
    url: "https://www.google.com/forms/about/",
  },
  {
    id: 5,
    title: "Zapier",
    description: "desc",
    img: Img5,
    url: "https://zapier.com/",
  },
];
