import React from "react";
import Inspiration from "./Inspiration";
import Layout from "./../Layout/Layout";
import Video from "./Video";
import Tools from "./Tools";
import BookInspirations from "./BookInspirations";
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";
import Relevant from "./Relevant"
const Index = () => {
  return (
    <Layout>
      <Video />
      <Inspiration />
      <Relevant />
      <Tools />
      <FrequentlyAskedQuestions />
      <BookInspirations />
    </Layout>
  );
};

export default Index;
