import React from "react";
import Img1 from "./../../images/books/1.jpg";
import Img2 from "./../../images/books/2.jpg";
import Img3 from "./../../images/books/3.jpg";
import Img4 from "./../../images/books/4.jpg";
import Img5 from "./../../images/books/5.jpg";
import Img6 from "./../../images/books/6.jpg";

function BookInspirations() {
  return (
    <div className=" bg-green-default">
      <div className="section-container">
        <div className="flex w-full">
          <h1 className="heading md:mb-20 mb-10">Book inspirations</h1>
        </div>

        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1  gap-5 mb-20">
          {Databooks.map((Databook) => {
            return (
              <div className="bg-white px-6 py-5">
                <div className="flex flex-col justify-between h-72 w-full items-center">
                  <div className="w-full flex justify-center">
                    <img src={Databook.img} alt="" className="h-52" />
                  </div>
                  <a href={Databook.url} target="__blank" className="hover:text-green-default boxes-links text-center ">
                    {Databook.title}
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default BookInspirations;

const Databooks = [
  {
    id: 1,
    title: "Speculative Everything: Design, Fiction, and Social Dreaming",
    description: "desc",
    img: Img1,
    url: "https://www.researchgate.net/publication/276156613_Speculative_Everything_Design_Fiction_and_Social_Dreaming",
  },
  {
    id: 2,
    title: "Kultur der Digitalität",
    description: "desc",
    img: Img2,
    url: "https://www.researchgate.net/publication/328389189_Felix_Stalder_Kultur_der_Digitalitat",
  },
  {
    id: 3,
    title: "Das geheime Leben der Bäume",
    description: "desc",
    img: Img3,
    url: "https://www.blinkist.com/de/books/das-geheime-leben-der-baume-de",
  },
  {
    id: 4,
    title: "Die technologische Bedingung",
    description: "desc",
    img: Img4,
    url: "https://www.suhrkamp.de/buecher/die_technologische_bedingung-_29603.html",
  },
  {
    id: 5,
    title: "The Mushroom at the End of the World",
    description: "desc",
    img: Img5,
    url: "https://press.princeton.edu/books/paperback/9780691178325/the-mushroom-at-the-end-of-the-world",
  },
  {
    id: 6,
    title: "Governing the Commons and the Rational Choice Controversy",
    description: "desc",
    img: Img6,
    url: "https://www.researchgate.net/publication/265017003_Elinor_Ostrom's_Legacy_Governing_the_Commons_and_the_Rational_Choice_Controversy",
  },
];
