import React from "react";
import Img1 from "./../../images/inspiration/1.jpg";
import Img2 from "./../../images/inspiration/2.png";
import Img3 from "./../../images/inspiration/3.png";
import Img4 from "./../../images/inspiration/4.jpg";
import Img5 from "./../../images/inspiration/5.jpeg";

function Inspiration() {
  return (
    <div className=" bg-green-default">
      <div className="section-container">
        <div className="flex w-full justify-center">
          <h1 className="heading md:mb-20 mb-10">Inspiration</h1>
        </div>
        <div>
          <h1 className="heading md:mb-20 mb-10">
            This is what some media say about Bioblinds
          </h1>
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1  gap-5">
          {Inspires.map((Inspire) => {
            return (
              <div>
                <div className="w-full bg-white py-5 px-6 h-44 flex flex-col justify-between flex-wrap">
                  {" "}
                  <div className="flex justify-center w-full">
                    <img src={Inspire.img} alt="" className=" h-14" />
                  </div>
                  <div className="w-full text-center ">
                    <div className="pt-6">
                      <a href={Inspire.url} target="__blank" className="boxes-links">Read More </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Inspiration;

const Inspires = [
  {
    id: 1,
    title: "title",
    description: "desc",
    img: Img1,
    btn: "Read more",
    url:"http://thegreenskylineinitiative.com/files/fraunhofer.pdf",
  },
  {
    id: 2,
    title: "title",
    description: "desc",
    img: Img2,
    btn: "Read more",
    url:"http://thegreenskylineinitiative.com/files/kurier.pdf",
  },
  {
    id: 3,
    title: "title",
    description: "desc",
    img: Img3,
    btn: "Read more",
    url:"http://thegreenskylineinitiative.com/files/io.pdf",
  },
  {
    id: 4,
    title: "title",
    description: "desc",
    img: Img4,
    btn: "Read more",
    url:"http://thegreenskylineinitiative.com/files/taspo.pdf",
  },
  {
    id: 5,
    title: "title",
    description: "desc",
    img: Img5,
    btn: "Read more",
    url:"https://www.lilligreen.de/pflanzengardinen-zum-selbermachen/",
  },
];
