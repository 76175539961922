import React from "react";
import {Link} from "gatsby";

function FrequentlyAskedQuestions() {
  return (
    <div className=" bg-green-default">
      <div className="section-container">
        <div className="flex w-full">
          <h1 className="heading mb-10">Frequently Asked Questions</h1>
        </div>
          See{" "}<span className="info-items"><Link to="/diy-editions-6">DIY-editions</Link></span>
          <div>
          <h1 className="heading mt-9 mb-10">More Inspiration</h1>
          <h1 className="text-2xl text-white font-bold mt-6">
            Some science & climate stuff
          </h1>
          <ul>
            <li className="info-items"><a href="https://nasa.gov/" target="__blank" className="info-items">– NASA</a></li>
            <li className="info-items"><a href="http://www.who.int/" target="__blank" className="info-items">– WHO</a></li>
            <li className="info-items"><a href="http://www.sustainability-reports.com/" target="__blank" className="info-items">– Sustainability reports</a></li>
            <li className="info-items"><a href="http://www.radio.garden/" target="__blank" className="info-items">– Radio stations</a></li>
            <li className="info-items"><a href="http://www.weltderphysik.de/" target="__blank" className="info-items">– Welt der Physik</a></li>
            <li className="info-items"><a href="http://www.carbonfootprint.com/calculator.aspx" target="__blank" className="info-items">– Carbon calculator</a></li>
            <li className="info-items"><a href="https://www.researchgate.net/publication/309759072_Vertical_greenery_systems_for_energy_savings_in_buildings_A_comparative_study_between_green_walls_and_green_facades" target="__blank" className="info-items">– Vertical greenery systems for energy savings in buildings: A comparative study between green walls and green facades</a></li>
            <li className="info-items"><a href="http://car.rene-grothmann.de/doc_en/index.html" target="__blank" className="info-items">– Ruler and compass</a></li>
            <li className="info-items"><a href="http://www.terra0.org/" target="__blank" className="info-items">– terra0</a></li>
            <li className="info-items"><a href="http://www.lilligreen.de/" target="__blank" className="info-items">– Lilli Green</a></li>
            <li className="info-items"><a href="https://www.worldometers.info/" target="__blank" className="info-items">– Worldometer</a></li>
            <li className="info-items"><a href="https://www.dkv.global/" target="__blank" className="info-items">– Deep Knowledge Group</a></li>
          </ul>
          <h1 className="text-2xl text-white font-bold mt-6">
            Great projects & organzizations worth supporting
          </h1>
          <ul>
            <li className="info-items"><a href="http://www.creativecommons.org/" target="__blank">– Creative Commons</a></li>
            <li className="info-items"><a href="https://www.fsf.org/" target="__blank">– Free Software Foundation</a></li>
            <li className="info-items"><a href="https://www.climaterealityproject.org/climate-101" target="__blank">– Climate Reality Project</a></li>
            <li className="info-items"><a href="https://www.gebaeudegruen.info/" target="__blank">– BuGG Bundesverband GebäudeGrün e. V.</a></li>
            <li className="info-items"><a href="https://vimeo.com/173031115" target="__blank">– Black Mountain</a></li>
          </ul>
          <h1 className="text-2xl text-white font-bold mt-6">Some cool platforms</h1>
          <ul>
            <li className="info-items"><a href="https://medium.com/" target="__blank">– Medium</a></li>
            <li className="info-items"><a href="https://enorm-magazin.de/" target="__blank">– Enorm</a></li>
            <li className="info-items"><a href="http://www.selbst.de/" target="__blank">– selbst.de</a></li>
            <li className="info-items"><a href="https://www.diyinternational.com/" target="__blank">– DIY International</a></li>
            <li className="info-items"><a href="http://www.newmodels.io/" target="__blank">– New Models</a></li>
          </ul>
          <h1 className="text-2xl text-white font-bold mt-6">
            What else could inspire you
          </h1>
          <ul>
            <li className="info-items"><a href="http://www.ninakraviz.com/" target="__blank">– Nina Kraviz</a></li>
            <li className="info-items"><a href="http://www.lyrapramuk.com/" target="__blank">– Lyra Pramuk</a></li>
            <li className="info-items"><a href="http://www.estherperbandt.com/" target="__blank">– Esther Perbandt</a></li>
            <li className="info-items"><a href="http://www.nyse.com/" target="__blank">– NYSE</a></li>
            <li className="info-items"><a href="https://www.potatopro.com/topics/potato-prices" target="__blank">– Potato Prices</a></li>
            <li className="info-items"><a href="http://www.dlg.org/en/" target="__blank">– German Farming Association</a></li>
            <li className="info-items"><a href="http://www.live-webcam-online.de/" target="__blank">– Live Webcams</a></li>
            <li className="info-items"><a href="https://www.plantsnap.com/" target="__blank">– PlantSnap</a></li>
            <li className="info-items"><a href="https://snake.googlemaps.com/" target="__blank">– Google Maps Snake</a></li>
            <li className="info-items"><a href="http://htmltetris.com/" target="__blank">– HTML Tetris</a></li>
            <li className="info-items"><a href="http://www.lawbf.com/" target="__blank">– LAWBF</a></li>
            <li className="info-items"><a href="https://player.fm/podcasts/Secret" target="__blank">– Secret Podcasts</a></li>
            <li className="info-items"><a href="https://www.youtube.com/watch?v=ric0XnCqcwc" target="__blank">– Free meditation masterclass</a></li>
            <li className="info-items"><a href="https://www.flightradar24.com/" target="__blank">– Flight Radar</a></li>
            <li className="info-items"><a href="https://www.cruisemapper.com/" target="__blank">– Cruise Radar</a></li>
            <li className="info-items"><a href="http://www.markcolle.com/" target="__blank">– Mark Colle</a></li>
            <li className="info-items"><a href="http://www.berghain.berlin/" target="__blank">– Berghain</a></li>
            <li className="info-items"><a href="http://www.traumabarundkino.de/" target="__blank">– Trauma Bar und Kino</a></li>
            <li className="info-items"><a href="http://www.daluma.de/" target="__blank">– Daluma</a></li>
            <li className="info-items"><a href="https://llotllov.com/" target="__blank">– Llot Llov</a></li>
            <li className="info-items"><a href="https://baerck.net/" target="__blank">– Baerck Studio</a></li>
            <li className="info-items"><a href="https://www.oliviertheyskens.com/" target="__blank">– Olivier Theyskens</a></li>
            <li className="info-items"><a href="http://andreaslux.com/" target="__blank">– Andreas Lux</a></li>
            <li className="info-items"><a href="https://www.berlinerfestspiele.de/en/gropiusbau/start.html" target="__blank">– Martin Gropius Bau</a></li>
            <li className="info-items"><a href="https://www.youtube.com/watch?v=SRXdilrbujM" target="__blank">– Software Rebellen – Die Macht des Teilens</a></li>
            <li className="info-items"><a href="https://rekids.com/" target="__blank">– Rekids</a></li>
            <li className="info-items"><a href="http://zuletazahr.com/" target="__blank">– Pablo Zuleta Zahr</a></li>
            <li className="info-items"><a href="https://www.tinoseubert.com/" target="__blank">– Tino Seubert</a></li>
            <li className="info-items"><a href="https://www.thespruce.com/how-many-vegetables-per-person-in-garden-1403355" target="__blank">– How Much to Plant per Person in the Vegetable Garden</a></li>
            <li className="info-items"><a href="https://www.skylinewebcams.com/top-live-cams.html/" target="__blank">– Skyline Webcams</a></li>
            <li className="info-items"><a href="http://www.friedrichshof-gruendau.de/" target="__blank">– Friedrichshof Niedergründau</a></li>
            <li className="info-items"><a href="http://janengel.berlin/" target="__blank">– Jan Engel</a></li>
            <li className="info-items"><a href="http://www.fachschule-veitshoechheim.bayern.de/" target="__blank">– fachschule-veitshoechheim.bayern.de
            </a></li>
            <li className="info-items"><a href="https://www.nationalpark-harz.de/de/startseite/" target="__blank">– Nationalpark Harz</a></li>
            <li className="info-items"><a href="https://www.agrar-dresden.de/" target="__blank">– Agrar Dresden AG</a></li>
            <li className="info-items"><a href="https://www.fassadengruen.de/" target="__blank">– fassadengruen.de</a></li>
            <li className="info-items"><a href="http://kraanswijk.nl/" target="__blank">– kraanswijk</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default FrequentlyAskedQuestions;
