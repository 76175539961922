import React from "react";
import Gif1 from "../../videos/inspiration-final.gif"

function Video() {
  return (
    <div className=" bg-green-default">
      <div className="max-w-7xl  mx-auto md:pt-30 sm:pt-20 pt-16 pb-2">
        <div className="flex justify-center text-center ">
          <div className="">
            <img
              src={Gif1}
              width="1300"
              className="px-10"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Video;
